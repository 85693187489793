import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { graphql, HeadProps } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { H1, H2, LargeP, P, Eyebrow, FourColCells, Layout, Grid, Feature, RevenueCalculator, Glow, DawnContactForm, Lifter, Card, HeadTags } from '@components'
import { usePositionInViewport } from '@utilities'

interface PageProps {
  data: Queries.PlatformPageQuery
}

const PlatformPage = ({ data: { sanityPage } }: PageProps) => {
  if (!sanityPage) return <></>
  const { sprites } = sanityPage
  const [t, setT] = useState(0)
  useScrollPosition(
    ({ currPos }) => setT(currPos.y), // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )
  return (
    <Layout>
      <Screen {...{ t }} glow initialV={1}>
        <Title>
          Keep your brand,
          <br />
          we power the full experience
        </Title>
        <SubTitle>Super-charge credit and rewards experiences for your customers</SubTitle>
      </Screen>

      <Screen {...{ t }} initialV={0} trimTop>
        <SectionHead>Power’s platform</SectionHead>
        <StyledGrid>
          <FourColCells platformSummary={sanityPage?.platformSummary} />
        </StyledGrid>
      </Screen>

      <Grid>
        <Section>
          <Audience>
            <Lifter {...{ t }}>
              <H2>Iconic consumer and business credit cards</H2>
              <P>Power was created for leading enterprises with bold ambitions to issue their own cards.</P>
            </Lifter>
          </Audience>

          <Card firstColumn background={sprites?.find(sprite => sprite?.key === 'box-consumer')?.image?.asset?.url || ''}>
            <div className="img" />
            <div>
              <Eyebrow>Consumer Cards</Eyebrow>
              <LargeP>Revered brands of the consumer internet, building generational products and services</LargeP>
            </div>
          </Card>
          <Card background={sprites?.find(sprite => sprite?.key === 'box-commercial')?.image?.asset?.url || ''}>
            <div className="img" />
            <div>
              <Eyebrow>Business Cards</Eyebrow>
              <LargeP>Titans of industry, championing the next era of B2B commerce</LargeP>
            </div>
          </Card>
          <Card background={sprites?.find(sprite => sprite?.key === 'box-banks')?.image?.asset?.url || ''}>
            <div className="img" />
            <div>
              <Eyebrow>Bank Programs</Eyebrow>
              <LargeP>Innovative financial institutions building next-generation financial platforms</LargeP>
            </div>
          </Card>
        </Section>
      </Grid>

      <Spacer />

      {sanityPage?.platformFeatures?.map((feature, i) => !!feature && <Feature key={`feature-${i}`} {...feature} {...{ t }} />)}

      <Grid>
        <Section>
          <RevenueCalculator {...{ t }} />
        </Section>
      </Grid>

      <Grid>
        <Section>
          <DawnContactForm />
        </Section>
      </Grid>
    </Layout>
  )
}

const Screen = ({ children, t, glow, initialV, trimTop }: { children: React.ReactNode; t: number; glow?: boolean; initialV: number; trimTop?: boolean }) => {
  const ref = useRef<HTMLDivElement>(null)
  const localT = usePositionInViewport(ref, t)

  const timeline = [
    { t: 0, v: initialV },
    { t: 0.35, v: 1 },
    { t: 0.5, v: 1 },
    { t: 1, v: 0 },
  ]

  return (
    <ScreenWrapper {...{ trimTop }}>
      {children}
      <Marker {...{ ref }} />
      {glow && <Glow {...{ timeline }} t={localT} />}
    </ScreenWrapper>
  )
}

const Spacer = styled.div`
  height: 200px;
`

const Marker = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 1px;
`

const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (max-width: 943px) {
    padding: 0;
  }
`

const ScreenWrapper = styled.div<{ trimTop?: boolean }>`
  position: relative;
  min-height: calc(${props => (props.trimTop ? 75 : 100)}vh - 50px);
  padding: 0 20px;
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.trimTop ? 'flex start' : 'center')};
  @media only screen and (min-width: 944px) {
    padding: 0;
    min-height: calc(${props => (props.trimTop ? 75 : 100)}vh - 65px);
    /* note this ^ does account for the optional top message banner, not too important though */
    grid-column: 1 / span 12;
  }
  text-align: center;
`

const Title = styled(props => <H1 {...props} />)`
  grid-column: 1 / span 6;
  text-align: center;
  @media only screen and (min-width: 944px) {
    grid-column: 2 / span 10;
  }
  margin-bottom: 1em;
`

const SubTitle = styled(props => <LargeP {...props} />)``

const Section = styled.section`
  display: contents;
`

const SectionHead = styled(props => <H2 {...props} />)<{ withTopMargin?: boolean }>`
  grid-column: 1 / span 6;
  @media only screen and (min-width: 944px) {
    text-align: center;
    grid-column: 3 / span 8;
  }
  margin-bottom: 1em;
  @media only screen and (max-width: 943px) {
    text-align: left;
    ${props =>
      props.withTopMargin &&
      `
      margin-top: 15vh;
      `}
  }
`

const Audience = styled.div`
  display: contents;
  h2 {
    grid-column: 1 / span 4;
    margin-top: 100px;
  }
  p {
    grid-column: 1 / span 6;
    margin-bottom: 50px;
  }
  @media only screen and (min-width: 944px) {
    h2 {
      margin-bottom: 0.5em;
    }
    h2,
    p {
      grid-column: 1 / span 5;
    }
  }
`

export const Head = (props: HeadProps) => (
  <HeadTags {...props}>
    <link rel="canonical" href="https://usepower.com/platform" />
  </HeadTags>
)

export const query = graphql`
  query PlatformPage {
    sanityPage(slug: { current: { eq: "platform" } }) {
      title
      slug {
        current
      }
      platformSummary {
        _rawCopy
        icon {
          asset {
            url
          }
        }
      }
      platformFeatures {
        _rawCopy
        icon {
          asset {
            url
          }
        }
      }
      sprites {
        key
        altText
        image {
          asset {
            url
          }
        }
      }
    }
  }
`

export default PlatformPage
